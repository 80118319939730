import $ from 'jquery';

const faqHeadings = $('.FaqItem__heading');

faqHeadings.on('click', function () {
    const parentElement = $(this).closest('.FaqItem');
    const contentElement = parentElement.find('.FaqItem__content');

    parentElement
        .find('.FaqItem__button')
        .html('-')
        .closest('.FaqItem')
        .siblings()
        .find('.FaqItem__button')
        .html('+');

    if (contentElement.is(':visible')) {
        contentElement.slideUp();
        parentElement.find('.FaqItem__button').html('+');
    } else {
        contentElement
            .slideDown()
            .closest('.FaqItem')
            .siblings()
            .find('.FaqItem__content')
            .slideUp();

        parentElement.find('.FaqItem__button').html('-');
    }
});
