import Swiper, { Navigation, Pagination, EffectCreative, Autoplay, Parallax } from 'swiper';

import 'swiper/css/bundle';

const swiper = new Swiper('.basic-slider', {
    modules: [Navigation, Pagination, EffectCreative, Autoplay, Parallax],
    effect: 'creative',
    loop: true,
    speed: 1000,
    parallax: {
        enabled: true,
    },
    creativeEffect: {
        prev: {
            // will set `translateZ(-400px)` on previous slides
            translate: [0, 0, -400],
        },
        next: {
            // will set `translateX(100%)` on next slides
            translate: ['100%', 0, 0],
        },
    },
    autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    pagination: {
        el: '.basic-slider .swiper-pagination',
        type: 'custom',
        renderCustom: (swiper, current, total) => {
            return `<span class="swiper-pagination-current">0${current}</span><span>0${total}</span>`;
        },
    },
    navigation: {
        nextEl: '.swiper-next-btn',
        prevEl: '.swiper-prev-btn',
    },
});
