import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

if (document.querySelector('.products-slider')) {
    const swiper = new Swiper('.products-slider .swiper', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        speed: 700,
        spaceBetween: 16,
        slidesPerView: 1,
        breakpoints: {
            550: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: document
                    .querySelector('.products-slider')
                    ?.getAttribute('data-columns'),
            },
        },

        // autoplay: {
        //     delay: 3500,
        //     disableOnInteraction: false,
        //     pauseOnMouseEnter: true,
        // },
        // pagination: {
        //     el: '.products-slider .swiper-pagination',
        //     type: 'custom',
        //     renderCustom: (swiper, current, total) => {
        //         return `<span class="swiper-pagination-current">0${current}</span><span>0${total}</span>`;
        //     },
        // },
        // navigation: {
        //     nextEl: '.swiper-next-btn',
        //     prevEl: '.swiper-prev-btn',
        // },
    });
}
