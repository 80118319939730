import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

const swiper = new Swiper('.opinions-slider', {
    modules: [Navigation, Pagination, Autoplay],
    speed: 400,
    spaceBetween: 20,
    navigation: {
        nextEl: '.Opinions__arrow--right',
        prevEl: '.Opinions__arrow--left',
    },
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: '.Opinions__pagination',
        clickable: true,
    },
});
