const listItems = document.querySelectorAll('li.menu-item-has-children > a');
const goBackBtns = document.querySelectorAll('.Navigation__subMenu__go-back');

const query = window.matchMedia('(max-width: 1024px)');

const initSubMenuFunctionality = () => {
    console.log(listItems);
    goBackBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
            const parentUl = btn.closest('ul');
            parentUl.classList.remove('open');
        });
    });

    const handleSubMenu = (ev) => {
        ev.preventDefault();
        const clickedLink = ev.currentTarget;
        const parent = clickedLink.closest('li');
        const nestedMenu = parent.querySelector('ul');
        nestedMenu.classList.add('open');
    };

    listItems?.forEach((listItem) => listItem.addEventListener('click', handleSubMenu));
};

if (query.matches) {
    initSubMenuFunctionality();
}

const handleMenuMobile = (param) => {
    if (param.matches) {
        initSubMenuFunctionality();
    }
};

query.addEventListener('change', handleMenuMobile);
