import './sass/style.scss';
import './js/app.js';

import * as components0 from '../views/components/faq/faq.js'; import * as components1 from '../views/components/menu/horizontal/horizontal.js'; import * as components2 from '../views/components/menu/shop/shop.js'; import * as components3 from '../views/components/opinions/opinions.js'; import * as components4 from '../views/components/searchform/index.js'; import * as components5 from '../views/components/slider/basic/index.js'; let components = [components0, components1, components2, components3, components4, components5];
import * as blocks0 from '../views/blocks/ProductsSlider/index.js'; let blocks = [blocks0];
import * as woocommerce0 from '../views/woocommerce/archive-product/archive-product.js'; import * as woocommerce1 from '../views/woocommerce/components/AddedToCartPopup/added-to-cart-popup.js'; import * as woocommerce2 from '../views/woocommerce/components/ProductGallery/product-gallery.js'; import * as woocommerce3 from '../views/woocommerce/components/SidebarCart/sidebar-cart.js'; let woocommerce = [woocommerce0, woocommerce1, woocommerce2, woocommerce3];

$(document).on('change', '#select_all', function () {
    if ($(this).is(':checked')) {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', true);
    } else {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', false);
    }
});
