const archiveContainer = document.querySelector('.ArchiveProduct__container');

const gridListViewButtons = document.querySelectorAll('.grid-list-switch');
const productsListContainer = document.querySelector('.ArchiveProduct .products');

if (archiveContainer) {
    const defaultColumnsNumber = archiveContainer.querySelector('.ArchiveContainer__content')
        .dataset.columns;

    gridListViewButtons.forEach((button) => {
        button.addEventListener('click', () => {
            if (button.dataset.view === 'list') {
                productsListContainer.classList.remove(`columns-${defaultColumnsNumber}`);
                productsListContainer.classList.add('columns-1');
            } else {
                productsListContainer.classList.remove('columns-1');
                productsListContainer.classList.add(`columns-${defaultColumnsNumber}`);
            }
        });
    });

    const openFiltersPanelButton = archiveContainer.querySelector('.toggle-filters');
    const sidebar = archiveContainer.querySelector('.ArchiveProduct__sidebar');

    openFiltersPanelButton.addEventListener('click', () => {
        archiveContainer.classList.toggle('show-filters');

        if (sidebar.style.display === 'block') {
            sidebar.style.display = 'none';
        } else {
            sidebar.style.display = 'block';
        }
    });
}
